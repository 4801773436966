import React from 'react'
import { Link } from 'gatsby'
import { Card, Animated, getAnimationVariant, Heading, theme } from '@roar/components'
import { mapperWithFunction } from '../common/utils'
import { Text } from '@roar/components'
import { formatImage } from './ImageWrapper'

const CARD_MAPPER = {
  name: ['name'],
  date: ['date'],
  description: ['description', 'childMarkdownRemark', 'excerpt'],
  slug: ['slug'],
  index: ['index'],
  image: (value) => formatImage(value, true),
}

const PostWrapper = (props) => {
  const { description, slug, name, image, index } = mapperWithFunction(props, CARD_MAPPER)

  return (
    <Link to={`/${slug}`}>
      <Animated shouldAnimate={true} variants={getAnimationVariant(props.indexInArray)}>
        <Card
          alignment="left"
          variant="standard"
          image={image}
          meta={() => (
            <Heading size="h7" color={theme.colors.brandRed}>
              Chapter {index + 1}
            </Heading>
          )}
          headline={name}
          description={description && <Text>{description}</Text>}
          mb={[2, 2, 4]}
        />
      </Animated>
    </Link>
  )
}

export default PostWrapper
